import se from '../../translation/se_SE'

export default {
  // i18nConfiguration
  i18n: {
    locales: [
      {
        code: 'se',
        iso: 'sv-SE',
      },
    ],
    defaultLocale: 'se',
    vueI18n: {
      fallbackLocale: 'se',
      messages: {
        se,
      },
    },
    pages: {
      'categories/business': {
        se: '/business',
      },
      'categories/get-inspired': {
        se: '/lat-dig-inspireras',
      },
      'categories/how-to': {
        se: '/hur-man-gor',
      },
      'categories/news': {
        se: '/nyheter',
      },
      'categories/printstories': {
        se: '/printstories',
      },
      'editorial-staff/index': {
        se: '/redaktion',
      },
      'editorial-staff/_author': {
        se: '/redaktion/:author?',
      },
    },
  },
  /* 
    Categories Configuration
    @order: [] è l'array delle chiavi delle categorie e rappresenta l'ordine con cui verranno visualizzate le categorie dove richieste
    list{
        @key è la chiave della categoria. Per convenzione chiamata come il folder dentro page.
        {
          @slug è lo slug della categoria inserito in categorie su Wordpress
          @view è la view di nuxt e corrisponde al name utilizzato nei nuxt-link
        }
    }
  */
  pages: {
    // leggere README.md
    list: [
      {
        key: 'index', // Home per comodità la trattiamo come una categoria
        wpUri: '/',
      },
      {
        key: 'business',
        wpUri: '/business/',
      },
      {
        key: 'get-inspired',
        wpUri: '/lat-dig-inspireras/',
      },
      {
        key: 'how-to',
        wpUri: '/hur-man-gor/',
      },
      {
        key: 'news',
        wpUri: '/nyheter/',
      },
      {
        key: 'printstories',
        wpUri: '/printstories/',
      },
      {
        key: 'editorial-staff',
        wpUri: '/redaktion/',
      },
    ],
  },
  categories: {
    // leggere README.md
    list: [
      {
        key: 'business',
        wpSlug: 'business',
        view: 'categories-business',
        wpCategoryId: 4096,
      },
      {
        key: 'get-inspired',
        wpSlug: 'lasciatiispirare',
        view: 'categories-get-inspired',
        wpCategoryId: 69,
      },
      {
        key: 'how-to',
        wpSlug: 'comesifa',
        view: 'categories-how-to',
        wpCategoryId: 67,
      },
      {
        key: 'news',
        wpSlug: 'lenovita',
        view: 'categories-news',
        wpCategoryId: 70,
      },
      {
        key: 'printstories',
        wpSlug: 'printstories',
        view: 'categories-printstories',
        wpCategoryId: 6980,
      },
      {
        key: 'highlights',
        wpSlug: 'highlights',
        view: null,
        wpCategoryId: 6979,
      },
      {
        key: 'business-growth',
        wpSlug: 'businessgrowth',
        view: null,
        wpCategoryId: 6978,
      },
    ],
  },
  pixartWebsite: 'https://www.pixartprinting.se/',
  navigation: {
    primary: {
      // TODO: Per rivisualizzare nel menu le categorie commentate basta togliere il commento.
      order: [
        'business',
        /* 'printstories', */ 'how-to',
        'get-inspired' /* 'news' */,
      ],
    },
    // Categorie che vengono filtrate da eventuali elenchi (es. la lista delle categorie a cui appartiene un post)
    exclude: ['highlights', 'business-growth'],
  },
  social: [
    {
      key: 'facebook',
      url: 'https://www.facebook.com/pixartprinting.se',
    },
    {
      key: 'instagram',
      url: 'https://www.instagram.com/pixarteurope/',
    },
    {
      key: 'linkedin',
      url: 'https://www.linkedin.com/company/pixartprinting/',
    },
  ],
  terms: [
    {
      key: 'privacy-policy',
      url: 'https://www.pixartprinting.se/integritet/',
    },
  ],
  shopLinks: [
    {
      label: 'Trycktjänster',
      url: 'https://www.pixartprinting.se/',
    },
    {
      label: 'Visitkort',
      url: 'https://www.pixartprinting.se/trycka-visitkort/',
    },
    {
      label: 'Reklamblad och flygblad',
      url: 'https://www.pixartprinting.se/smaformat/broschyrer-och-flygblad/',
    },
    {
      label: 'Etiketter',
      url: 'https://www.pixartprinting.se/tryck-etiketter-taggar/',
    },
    {
      label: 'Klistermärken',
      url: 'https://www.pixartprinting.se/tryck-etiketter-taggar/klistermarken/',
    },
    {
      label: 'Förpackningar',
      url: 'https://www.pixartprinting.se/tryck-forpackningar/',
    },
    {
      label: 'Förpackningar i kartong',
      url: 'https://www.pixartprinting.se/tryck-forpackningar/standardforpackningar/',
    },
    {
      label: 'Shoppingpåsar',
      url: 'https://www.pixartprinting.se/pasar-med-tryck/',
    },
    {
      label: 'Tidningar, böcker och kataloger',
      url: 'https://www.pixartprinting.se/tidningar-bocker-kataloger/',
    },
    {
      label: 'Canvastavlor',
      url: 'https://www.pixartprinting.se/fotoproducten/tryck-pa-duk/',
    },
    {
      label: 'Skräddarsydda gadgets',
      url: 'https://www.pixartprinting.se/skraddarsydda-gadgets/',
    },
    {
      label: 'Kalendrar och almanackor',
      url: 'https://www.pixartprinting.se/kalendrar/',
    },
  ],
}
