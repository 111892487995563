const structure = {
  htmlAttrs: {},
  meta: [],
  link: [],
}

const i18nHead = (tags = structure, i18nHead = structure) => {
  const htmlAttrs = { ...i18nHead.htmlAttrs, ...tags.htmlAttrs }
  const meta = [...i18nHead.meta, ...tags.meta]
  const link = [...i18nHead.link, ...tags.link]

  const script = []

  return {
    htmlAttrs,
    meta,
    link,
    script,
  }
}

const getLocaleIso = (i18n) =>
  i18n.locales.find((locale) => locale.code === i18n.locale)?.iso

const generateTags = (seo, i18n) => {
  return {
    title: seo?.title,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: seo?.metaDesc || '',
      },
      {
        hid: 'og:type',
        property: 'og:type',
        content: seo?.opengraphType,
      },
      {
        hid: 'og:site_name',
        property: 'og:site_name',
        content: seo?.opengraphSiteName,
      },
      {
        hid: 'og:locale',
        property: 'og:locale',
        content: getLocaleIso(i18n),
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: seo?.opengraphUrl,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: seo?.opengraphTitle,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: seo?.opengraphDescription || '',
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: seo?.opengraphImage?.sourceUrl,
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: seo?.twitterTitle,
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content: seo?.twitterDescription,
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: seo?.twitterImage?.sourceUrl,
      },
    ].filter((meta) => meta.content),
    link: [
      ...[...(seo?.hreflang || [])]?.map((link) => {
        return {
          hid: `i18n-alt-${link.lang || ''}-custom`,
          rel: 'alternate',
          href: link.url || '',
          hreflang: link.lang || '',
        }
      }),
    ],
  }
}

export default (context, inject) => {
  inject('i18nHead', i18nHead)
  inject('generateTags', generateTags)
}
