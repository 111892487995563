import nl from '../../translation/nl_NL'

export default {
  // i18nConfiguration
  i18n: {
    locales: [
      {
        code: 'nl',
        iso: 'nl-BE',
      },
    ],
    defaultLocale: 'nl',
    vueI18n: {
      fallbackLocale: 'nl',
      messages: {
        nl,
      },
    },
    pages: {
      'categories/business': {
        nl: '/business',
      },
      'categories/get-inspired': {
        nl: '/laat-je-inspireren',
      },
      'categories/how-to': {
        nl: '/hoe-het-moet',
      },
      'categories/news': {
        nl: '/nieuws',
      },
      'categories/printstories': {
        nl: '/printstories',
      },
      'editorial-staff/index': {
        nl: '/redactie',
      },
      'editorial-staff/_author': {
        nl: '/redactie/:author?',
      },
    },
  },
  /* 
    Categories Configuration
    @order: [] è l'array delle chiavi delle categorie e rappresenta l'ordine con cui verranno visualizzate le categorie dove richieste
    list{
        @key è la chiave della categoria. Per convenzione chiamata come il folder dentro page.
        {
          @slug è lo slug della categoria inserito in categorie su Wordpress
          @view è la view di nuxt e corrisponde al name utilizzato nei nuxt-link
        }
    }
  */
  pages: {
    // leggere README.md
    list: [
      {
        key: 'index', // Home per comodità la trattiamo come una categoria
        wpUri: '/',
      },
      {
        key: 'business',
        wpUri: '/business/',
      },
      {
        key: 'get-inspired',
        wpUri: '/laat-je-inspireren/',
      },
      {
        key: 'how-to',
        wpUri: '/hoe-het-moet/',
      },
      {
        key: 'news',
        wpUri: '/nieuws/',
      },
      {
        key: 'printstories',
        wpUri: '/printstories/',
      },
      {
        key: 'editorial-staff',
        wpUri: '/redactie/',
      },
    ],
  },
  categories: {
    // leggere README.md
    list: [
      {
        key: 'business',
        wpSlug: 'business',
        view: 'categories-business',
        wpCategoryId: 4096,
      },
      {
        key: 'get-inspired',
        wpSlug: 'lasciatiispirare',
        view: 'categories-get-inspired',
        wpCategoryId: 69,
      },
      {
        key: 'how-to',
        wpSlug: 'comesifa',
        view: 'categories-how-to',
        wpCategoryId: 67,
      },
      {
        key: 'news',
        wpSlug: 'lenovita',
        view: 'categories-news',
        wpCategoryId: 70,
      },
      {
        key: 'printstories',
        wpSlug: 'printstories',
        view: 'categories-printstories',
        wpCategoryId: 6980,
      },
      {
        key: 'highlights',
        wpSlug: 'highlights',
        view: null,
        wpCategoryId: 6979,
      },
      {
        key: 'business-growth',
        wpSlug: 'businessgrowth',
        view: null,
        wpCategoryId: 6978,
      },
    ],
  },
  pixartWebsite: 'https://nl.pixartprinting.be/',
  navigation: {
    primary: {
      // TODO: Per rivisualizzare nel menu le categorie commentate basta togliere il commento.
      order: ['business', 'printstories', 'how-to', 'get-inspired', 'news'],
    },
    // Categorie che vengono filtrate da eventuali elenchi (es. la lista delle categorie a cui appartiene un post)
    exclude: ['highlights', 'business-growth'],
  },
  social: [
    {
      key: 'facebook',
      url: 'https://www.facebook.com/pixartprinting.be.nl/',
    },
    {
      key: 'instagram',
      url: 'https://www.instagram.com/pixarteurope/',
    },
    {
      key: 'linkedin',
      url: 'https://www.linkedin.com/company/pixartprinting/',
    },
  ],
  terms: [
    {
      key: 'privacy-policy',
      url: 'https://nl.pixartprinting.be/privacy/',
    },
  ],
  shopLinks: [
    {
      label: 'Online drukkerij',
      url: 'https://nl.pixartprinting.be/',
    },
    {
      label: 'Visitekaartjes',
      url: 'https://nl.pixartprinting.be/visitekaartjes/',
    },
    {
      label: 'Leaflets en flyers',
      url: 'https://nl.pixartprinting.be/klein-drukwerk/printen-flyers/',
    },
    {
      label: 'Etiketten drukken',
      url: 'https://nl.pixartprinting.be/printen-etiketten-labels/',
    },
    {
      label: 'Stickers drukken',
      url: 'https://nl.pixartprinting.be/printen-etiketten-labels/stickers/',
    },
    {
      label: 'Verpakkingen',
      url: 'https://nl.pixartprinting.be/verpakkingen/',
    },
    {
      label: 'Kartonnen doosjes',
      url: 'https://nl.pixartprinting.be/verpakkingen/standaardverpakkingen/',
    },
    {
      label: 'Shopping bags',
      url: 'https://nl.pixartprinting.be/gepersonaliseerde-tassen/',
    },
    {
      label: 'Tijdschriften, boeken, catalogi',
      url: 'https://nl.pixartprinting.be/printen-tijdschriften-boeken-catalogi/',
    },
    {
      label: 'Afdrukken op canvas',
      url: 'https://nl.pixartprinting.be/fotoproducten/print-op-canvas/',
    },
    {
      label: 'Gadgets',
      url: 'https://nl.pixartprinting.be/gepersonaliseerde-gadgets/',
    },
    {
      label: "Kalenders en agenda's",
      url: 'https://nl.pixartprinting.be/kalenders/',
    },
  ],
  nuxtConfiguration: {
    head: {},
  },
}
